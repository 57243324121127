import { Redirect, Route } from "react-router-dom"
import { routes } from "../constants/pathRoutes"

const PrivateRoute = ({ component: Component, isAuthenticated, ...restProps }) => {
  return (
    <Route 
      {...restProps}
      component={
        (props) => (
          (isAuthenticated)
          ? <Component {...props} />
          : <Redirect to={routes.initial} />
        )
      }
    />
  )
}

export default PrivateRoute
