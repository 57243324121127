import { typesVehicles } from "./typesVehicles"

//  const initialState = {
//    loading   : false,
//    vehicles  : null,
//    myVehicles: null
//  }
export const VehiclesReducer = (state, action) => {

  switch (action.type) {
    case typesVehicles.InitAction:
      return{
        ...state,
        loading: true
      }
  
    case typesVehicles.GetVehicles:
      return{
        ...state,
        loading: false,
        vehicles: [...action.payload]
      }

    case typesVehicles.EndAction:
      return {
        ...state,
        loading: false,
      }
  
    default:
      return state
  }

}