import { createContext, useReducer } from "react"
import AuthReducer from "./AuthReducer"
import { authTypes } from "./authTypesReducer"
import { firebase, googleProvider } from "../../config/configFirebase"
import { NOT_VALID_EXTENTIONS } from "../../config/GeneralConfig"
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { alertAuthLogin, alertAuthRegister } from "../../utils/alertAuth"

export const AuthContext = createContext()

const AuthState = ({children}) => {

		const initialState = {
			user			: null,
			loading		: false,
			authError : false,
			isAuth    : false,
		}

    const [authState, dispatch] = useReducer(AuthReducer, initialState)

		// Iniciar sesion con google
		const loginWithGoogle = async () => {
			const mySwal = withReactContent(Swal)
			try {
				const credentials = await firebase
													.auth()
													.signInWithPopup( googleProvider )

				let emailValid = credentials.user.email.split("@")
				let extentionValid = emailValid[1].split(".")

				if( NOT_VALID_EXTENTIONS.indexOf(extentionValid[0]) !== -1 ){
					mySwal.fire({
						title: "Por favor usar dominios empresariales",
        		icon : "error"
					})
					await firebase.auth().signOut()
					return;
				}

				dispatch({
					type	 : authTypes.LoginUser,
					payload: credentials.user
				})

				await firebase
					.firestore()
					.collection("users")
					.doc(credentials.user.uid)
					.set(
						{
							email: credentials.user.email,
							name : credentials.user.displayName
						}
					);
				
			} catch (error) {
				console.log(error)
				callAlert(error?.code)
			}
		}

		// Registrar nuevo usuario
		const registerNewUser = async ( user, email, password ) => {
			try {
				const credentials = await firebase
														.auth()
														.createUserWithEmailAndPassword( email, password )
				
				await credentials.user.updateProfile({
					displayName: `${user.name} ${user.lastname}` 
				})

				dispatch({
					type	 : authTypes.LoginUser,
					payload: credentials.user
				})

				await firebase
					.firestore()
					.collection("users")
					.doc(credentials.user.uid)
					.set(user);

			} catch (error) {
				console.log(error)
				callAlert(error?.code)
			}
		}

		// Enviar correo para cambio de contraseña
		const sendEmailChangePassword = async ( email ) => {
			try {
				await firebase.auth().sendPasswordResetEmail( email )
			} catch (error) {
				console.log(error)
				alertAuthRegister(error?.code)
			}
		}

		// Iniciar sesion con correo y contraseña
		const loginWithEmail = async ( email, password ) => {
			try {
				const credentials = await firebase
														.auth()
														.signInWithEmailAndPassword(email, password)
				dispatch({
					type	 : authTypes.LoginUser,
					payload: credentials.user
				})

			} catch (error) {
				console.log(error)
				callAlert(error?.code)
			}
		}
		
		// Al resfrescar navegador
		const getCurrentUser = () => {
			try {
				firebase.auth().onAuthStateChanged( async (user) => {
					if(user){
						dispatch({ 
							type   : authTypes.LoginUser, 
							payload: user
						})
					}else{
						dispatch({ type: authTypes.LogoutUser })
						await firebase.auth().signOut()
					}
				})
			} catch (error) {
				callAlert(error?.code)
			}
		}

		const callAlert = ( code ) => alertAuthLogin(code)
		
    return (
      <AuthContext.Provider
				value={{
					authState,
					getCurrentUser,
					loginWithGoogle,
					loginWithEmail,
					registerNewUser,
					sendEmailChangePassword
				}}
			>
          {children}
      </AuthContext.Provider>
    )
}

export default AuthState
		
		// Errors Register
		// try {
    //   await firebase.registerUser(user, inputEmail, inputPassword);
    // } catch (error) {
    //   var errorCode = error.code;
    //   switch (errorCode) {
    //     case "auth/email-already-in-use":
    //       MySwal.fire({
    //         title: "La dirección de email ya existe.",
    //         icon: "error"
    //       });
    //       break;
    //     case "auth/weak-password":
    //       MySwal.fire({
    //         title: "La contraseña debe tener mínimo 6 caracteres",
    //         icon: "error"
    //       });
    //       break;
    //     case "auth/network-request-failed":
    //       MySwal.fire({
    //         title:
    //           "Se ha producido un error de red (como tiempo de espera, conexión interrumpida o host inaccesible).",
    //         icon: "error"
    //       });
    //       break;
    //     default:
    //       //Declaraciones ejecutadas cuando ninguno de los valores coincide con el valor de la expresión
    //       MySwal.fire({
    //         title: "Porfavor validar la información ingresada en el formulario",
    //         icon: "error"
    //       });
    //       break;
    //   }
    // }

		//Reset password
		// catch (error) {
    //   MySwal.fire({
    //     title: "El correo electrónico no se encuentra registrado.",
    //     icon : "error"
    //   });