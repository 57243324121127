import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import Swal from "sweetalert2";
import { config } from "./configFirebase";
import withReactContent from "sweetalert2-react-content";
import { NOT_VALID_EXTENTIONS } from "../config/GeneralConfig";

class Firebase {
  constructor() {
    if (!app.apps.length) {
      app.initializeApp(config);
    }
    this.auth = app.auth();
    this.db = app.firestore();
    this.provider = new app.auth.GoogleAuthProvider();
  }

  // registrar un usuario

  async registerUser(user, email, password) {
    const newUser = await this.auth.createUserWithEmailAndPassword(
      email,
      password
    );

    await newUser.user.updateProfile({
       displayName: user.name + " " + user.lastname
    })

    await this.db
      .collection("users")
      .doc(newUser.user.uid)
      .set(user);
  }

  async emailLogin(email, password) {
    // console.log(email, password)
     await this.auth.signInWithEmailAndPassword(email, password);
  }

  async googleLogin() {
    const MySwal = withReactContent(Swal);
    const user = await this.auth.signInWithPopup(this.provider);
    //console.log(user.user);
    let newUser = user.user;

    let emailValid = newUser.email.split("@");
    //  console.log(emailValid);
    let extentionValid = emailValid[1].split(".");

    if (NOT_VALID_EXTENTIONS.indexOf(extentionValid[0]) !== -1) {
      MySwal.fire({
        title: "Por favor usar dominios empresariales",
        icon: "error"
      });
      this.logout();
      return;
    }

    await this.db
      .collection("users")
      .doc(newUser.uid)
      .set({
        email: newUser.email,
        name: newUser.displayName
      });
  }

  restorePassword(email) {
    return this.auth.sendPasswordResetEmail(email);
  }

  async logout() {
    await this.auth.signOut();
  }
}

const firebase = new Firebase();

export default firebase;
