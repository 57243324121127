import { createContext, useReducer } from "react"
import { TripReducer } from "./TripReducer"
import firebase from '../../config/Firebase'
import { typesTrip } from "./typesTrip"

export const TripContext = createContext()

const TripState = ({children}) => {

  const initialState = {
    loading     : false,
    tripHistory : null,
    error       : null,
    tripFavorite: []
  }

  const [ tripState , dispatch] = useReducer(TripReducer, initialState)  

  const getTripsHistory = ( userId ) => {
    dispatch({ type: typesTrip.InitAction })
    try {
       firebase
        .db
        .collection("users")
        .doc( userId )
        .collection("journeys")
        .onSnapshot((query) => {
          let history = []
          query.forEach( (trip) => {
            history.push({
              ...trip.data(),
              id: trip.id
            })
          })
          dispatch({
            type : typesTrip.GetTipsHistory,
            payload: history
          })

        })
    } catch (error) {
      console.log(error)
      dispatch({
        type: typesTrip.ErrorAction,
        payload: error
      })
    }
  }

  const getFavoriteTrips = ( userUid ) => {
    try {

      firebase
        .db
        .collection("users")
        .doc(userUid)
        .collection("journeys")
        .where("favorite", "==", true)
        .onSnapshot((query) => {
          let favorite = []
          query.forEach( (trip) => {
            favorite.push({
              id: trip.id
            })
          })
        })
      
    } catch (error) {
      console.log(error);
      dispatch({
        type: typesTrip.ErrorAction,
        payload: error
      })
    }
  }
  

  return (
    <TripContext.Provider
      value={{
        tripState,
        getTripsHistory,
        getFavoriteTrips
      }}
    >
      { children }
    </TripContext.Provider>
  )
}

export default TripState
