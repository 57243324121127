import { createContext, useReducer } from "react";
import { firestore } from "../../config/configFirebase";
import { typesVehicles } from "./typesVehicles";
import { VehiclesReducer } from "./VehiclesReducer";

export const VehicleContext = createContext()

const VehicleState = ({ children }) => {

  const initialState = {
    loading   : false,
    vehicles  : [],
    myVehicles: []
  }

  const [state, dispatch] = useReducer( VehiclesReducer, initialState)

  const getVehicles = () => {
    try {
      firestore
        .collection('vehicles_type')
        .where("type_injection", "==", "Electrónica")
        .orderBy("order")
        .onSnapshot( snap => {
          let vehicles = []
          snap.forEach( doc => {
            vehicles.push({
              id: doc.id,
              ...doc.data()
            })
          })
          dispatch({
            type: typesVehicles.GetVehicles,
            payload: vehicles
          })
        })
      dispatch({ type: typesVehicles.EndAction })
    } catch (error) {
      console.log(error)
      dispatch({ type: typesVehicles.EndAction })
    }
  }
  

  return(
    <VehicleContext.Provider
      value={{
        state,
        getVehicles,
      }}
    >
      { children }
    </VehicleContext.Provider>
  )
}

export default VehicleState