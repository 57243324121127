import { typesTrip } from "./typesTrip"

// const initialState = {
//   loading    : false,
//   tripHistory: null
//   error      : null
// }

export const TripReducer = ( state, action ) => {

  switch (action.type) {

    case typesTrip.InitAction:
      return{
        ...state,
        loading: true,
      }

    case typesTrip.GetTipsHistory:
      return{
        ...state,
        loading    : false,
        tripHistory: [...action.payload],
      }

    case typesTrip.ErrorAction:
      return{
        ...state,
        loading: false,
        error  : action.payload,
      }
  
    default:
      return state
  }

}