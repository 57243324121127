import firebase, { FirebaseContext } from "./config";
import TripState from "./context/trips/TripState";
import AuthState from "./context/auth/AuthContext";
import AppRouter from "./routes/AppRouter";
import VehicleState from "./context/vehicles/VehicleState";

const App = () => {
  
  return (
    <>
      <FirebaseContext.Provider value={{ firebase }}>
        <AuthState>
          <TripState>
            <VehicleState>
              <AppRouter />
            </VehicleState>
          </TripState>
        </AuthState>
      </FirebaseContext.Provider>
    </>
  );
};

export default App;